/* You can add global styles to this file, and also import other style files

html, body { height: 100%; }
body { margin: 0; font-family: Avenir, Roboto, "Helvetica Neue", sans-serif; }

.mat-reusable-shadow {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    outline: none;
    border: none;
    border-radius: 4px;
}

.spacer {
    flex: 1;
} */

/*body
{
	height: 100vh;
}
*/
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
/* @import "~@ng-select/ng-select/themes/material.theme.css"; */
:root {
	--lp-font-family: 'Poppins','sans-serif';
	--lp-background: #214773;
	--lp-background-red:#ED4036;
	--lp-color-white: #ffffff;
	--lp-color-black: #000000;
	--lp-color-info: #0c538d;
	--lp-color-success: #07bc0c;
	--lp-color-warning: #f1c40f;
	--lp-color-error: #e74c3c;
	--lp-color-transparent: hsla(0,0%,100%,.7);
	--lp-icon-color-info: var(--lp-color-info);
	--lp-icon-color-success: var(--lp-color-success);
	--lp-icon-color-warning: var(--lp-color-warning);
	--lp-icon-color-error: var(--lp-color-error);
	--lp-text-color-light: #757575;
	--lp-text-color-dark: #000;
	--lp-text-color-white: #fff;
	--lp-text-color-info: var(--lp-color-info);
	--lp-text-color-success: var(--lp-color-success);
	--lp-text-color-warning: var(--lp-color-warning);
	--lp-text-color-error: var(--lp-color-error);
	--lp-font-regular-normal:400;
	--lp-font-regular-medium:500;
	--lp-font-regular-semibold:600;
	--lp-font-regular-Bold:700;
}
*{
	font-family: var(--lp-font-family);
	/* font-weight: var(--lp-font-regular-medium); */
}
.mat-drawer-content
{
	overflow: hidden !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
    opacity: 0.03 ;
    background-color: var(--lp-background)!important;
  }

.mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: var(--lp-background)!important;
  }
.content-container
{
  width:92%;
  margin:0 auto;
  padding: 50px 0;
  position: relative;
}

.title
{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.route-count-badge{
    width: 30px !important;
    height: 30px !important;
	line-height: 26px;
    top: -3px;
	left: 0px;
    position: relative;
    text-align: center;
    border-radius: 50%!important;
    color: #f8f9fa!important;
    background-color: #214773 !important;
    border: 1px solid #ffffff !important;
    filter: contrast(1);
}
.route-count-badge-conatiner {
	left: -6px;
    background: url(./assets/teenyicons_pin-solid.png) no-repeat;
    background-size: 100% 39px;
    height: 41px;
    padding: 5px;
    top: -11px;
    position: absolute;
}
.content-container h1
{
	margin:0;
	padding:0;
	font-size: 30px;
	font-weight: 300;
	color: #000000;
	font-family: var(--lp-font-family);
	padding-bottom: 4px;
	border-bottom:2px solid #DADADA;
	width:100%;
}

.content-container h2
{
	margin:0;
	padding:0;
	font-size: 2rem;
	font-weight: normal;
	color: #505050;
	font-family: var(--lp-font-family);
	padding-bottom: 10px;
	border-bottom:2px solid #a7a7a7;
	letter-spacing: -1px;
	width:100%;
}

.sub-title
{
	display: flex;
	justify-content: center;
	align-items: center;
/*	margin:4rem 0;*/
}

.sub-title h1
{
	margin:0;
	padding:0;
	font-size: 2.8rem;
	font-weight: normal;
	color: #505050;
	font-family: var(--lp-font-family);
	padding-bottom: 10px;
	letter-spacing: -1px;
	width:100%;
	margin-left: 1rem;
	border-bottom: 0 !important;
}

.address-col
{
	width:200px;
	text-align: left;
}

.icon-blue
{
	color:#0C4675;
}

.signup-wrapper
{
	width: 70%;
}
.custom-select-arrow::after {
    content: "";
    background: url('./assets/down-arrow.png') no-repeat;
    position: absolute;
    right: 10px;
    top: 15px;
	padding: 10px;
    height: 15px;
	z-index:10;
    width: 15px;
    pointer-events: none;
	-moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.mat-select-arrow {
	border:unset !important;
    background: url('./assets/down-arrow.png') no-repeat;
    height: 15px !important;
    width: 15px !important;
    pointer-events: none;
    -webkit-appearance: none;
    appearance: none;
}
@media screen and (max-width: 767px) {
 .big-ass-button, .cancel-button
{
  	width: 100% !important;
}

.big-ass-button{
	margin:2rem 0 !important;
}

  .col-md-10
{
	width: 100% !important
}
.form-signup-Image
{
    width: 92% !important;
    margin:0 auto;
    margin-bottom: 40px;
}
/*.col-md-4, .col-md-6
{
	width: 100% !important;
	margin-bottom: 2rem;
}*/

/*.Colpad
{
	padding-right: 0 !important;
}*/
.signBtn
{
	padding-right: 0 !important;
	padding-left: 0 !important;
	margin: 0 !important;
}
/*.btn
{
	display: flex;
	flex-direction: column;
	justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}*/
.mat-button-toggle-group {
	border: none;
	background: transparent;
	display: flex;
	flex-wrap: wrap;
}
.btnColor
{
	margin-left: 0 !important;
}
/*span
{
	display: block !important;
	   
}*/

span>div {
 margin: 2rem 0 !important;
	}

button.form-control
{
	width: 100% !important;
}

.mat-form-field {
	display: block !important;
	}
.button-group
{
	flex-direction: column;
}
/*.carousel-indicators
{
	display: none;
}
*/
.carousel-indicators
{
	margin-left: 5% !important;
	margin-right: 5% !important;
}
.slide .carousel-indicators button
{
	width: 5px !important;
	height: 5px !important;
	border-radius: 50%;
}
}

@media screen and (min-width: 768px) and (max-width: 1499px) 
{
	
.mapboxgl-ctrl-geocoder--input
{
	width: 130px;
}
.col-md-10.formText
{
	width: 100% !important
}
.big-ass-button
{
	width:31% !important;	
}

.container-login-btn .title
{
	/*font-size: 1.5rem !important;*/
}

/*.form-signup-Image
{
    width: 92% !important;
    margin:0 auto;
    margin-bottom: 40px;
}*/
/*.col-md-4, .col-md-6
{
	width: 100% !important;
	margin-bottom: 2rem !important;
}*/

.Colpad
{
	padding-right: 0 !important;
}
.signBtn
{
	padding-right: 0 !important;
	padding-left: 0 !important;
	margin-top:1rem !important;
}
}

@media screen and (min-width: 1500px) and (max-width: 1760px) 
{
.big-ass-button
{
	width:25% !important;
}
.form-signup-Image
{
    width: 92% !important;
    margin:0 auto;
    margin-bottom: 40px;
}
/*.col-md-4, .col-md-6
{
	width: 100% !important;
}*/

.Colpad
{
	padding-right: 0 !important;
}
.signBtn
{
	padding-right: 0 !important;
	padding-left: 0 !important;
}
.imagePlacing
{
	width: 100% !important;
}
}

/* @font-face {
    font-family: var(--lp-font-family);
    src: local('Dunbar Tall Regular'), local('DunbarTall-Book-Regular'),
        url('src/assets/font/DunbarTall-Book.woff2') format('woff2'),
        url('src/assets/font/DunbarTall-Book.woff') format('woff'),
        url('src/assets/font/DunbarTall-Book.ttf') format('truetype');
    font-style: medium;
} */


/*.big-ass-button {
	display: inline-flex;
	justify-content: center;
	padding: 0px 0px;
	font-size: 1rem;
	text-align: center;
	border: 2px solid #333;
	background-color: transparent;
	color: #333;
	width: 25%;
	height: 45px;
	margin-top: 5%;
	margin-left: 20%;
	margin-right: 5%;
}

*/

.big-ass-button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	text-align: center;
	border: 1px solid #214773;
	border-radius: 10px;
	/*background-color: transparent;*/
	color: #fff !important;
	width: 270px;
	/*height: 45px;*/
	padding: 12px 0;
	cursor: pointer;
	/*margin-top: 5%;
	margin-left: 20%;*/
	background-color: #214773 !important;
	font-family: var(--lp-font-family);
	margin-right: 1%;
	position: relative;
}

.big-ass-button:last-child {
	margin-right: 0;
}

.big-ass-button:hover {
	background-color: #0C4572;
}

.cancel-button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0px 0px;
	font-size: 18px;
	text-align: center;
	border: 1px solid #214773;
	border-radius: 10px;
	padding: 12px 0;
	/*background-color: transparent;*/
	color: #161616;
	width: 270px;
	/*height: 45px;*/
	cursor: pointer;
	font-family: var(--lp-font-family);
	/*margin-top: 5%;
	margin-left: 20%;*/
	/* background-color: #0C4572 !important; */
	position: relative;
	background: transparent;
}

.button-group
{
	display: flex;
	align-items: center;
}

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('src/assets/font/glyphicons-halflings-regular.eot');
    src: url('src/assets/font/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
    url('src/assets/font/glyphicons-halflings-regular.woff') format('woff'), 
    url('src/assets/font/glyphicons-halflings-regular.ttf') format('truetype'), 
    url('src/assets/font/glyphicons-halflings-regular.svg#glyphicons-halflingsregular') format('svg');
}


.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*.big-ass-button:after {
    content: "\e258";
    font-family: 'Glyphicons Halflings';    
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    font-size: 18px;
    cursor: pointer;
    color: #fff;    
    position: absolute;
    z-index: 99;
    top: 12px;
    right: 10px;
}

.cancel-button:after {
    content: "\e258";
    font-family: 'Glyphicons Halflings';    
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    font-size: 18px;
    cursor: pointer;
    color: #0C4572;    
    position: absolute;
    z-index: 99;
    top: 12px;
    right: 10px;
}*/

.favorites-container
{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top:2rem;
}


.favorite-card
{
	max-width: 250px;
}

.centered, .editprofile, .agent-thumbnail, .bi-plus-square
{
	cursor: pointer;
}

.form-control
{
	border-radius:10px !important;
}



.table-responsive
{
	width: 100%;
}

@media screen and (max-width: 599px)
{
.Colpad {
    padding-right: 10px !important;
}
.clients-icon-list
{
	width: 40px !important;
}
}

.clients-icon-list
{
	width: 64px;
}

@media screen and (min-width: 320px) and (max-width: 360px)
{
.empty-space
{
    margin-top: -54px !important;
}
}


@media screen and (min-width: 320px) and (max-width: 599px)
{
/*.empty-space
{
    margin-top: -54px !important;
}*/
.horizontalline
{
	margin-bottom: -19px !important;
}
.welcome
{
	font-size: 1.5rem !important;
}
.agent-thumbnail
{
	height: 34px !important;
	width: 34px !important;
}
.small-buttons
{
	top: 136px !important;
}
.green
{
	line-height: 1.2 !important;
}
.centertoleft {
	position: relative;
	animation: none !important;
}

.centertoright {
	position: relative;
	animation: none !important;
}
.bg-img
{
width: 220px !important;
}
.child-div
{
	font-size: 1.5rem !important;
}

.agent-details span:last-child
{
	text-align: right;
}
.column-1, .column-2, .column-3
{
	width: 100% !important;
}
.address-header
{
	font-size: 2rem !important;
	font-weight: bold !important;
}
.inputLine
{
	width: 100%;
}
.address-item
{
	line-height: 1.2;
}
.mobile-margin
{
	margin-top:1rem !important;
}

.button-container
{
	width: auto !important;
	margin-top: 2rem;
}
.cell-container
{
	width: auto !important;
}
.example-box
{
	flex-direction: column !important;
}
.signout_text
{
	right: 25px !important;
}

.mat-expansion-panel
{
	display: block !important;
}

.border-1
{
	border:0 !important;
	padding-left: 7px !important;
}
.mat-expansion-panel-header
{
	/*padding-top:77px !important;
	padding-bottom: 77px !important;*/
}
.column1, .column2, .column3
{
	width: 100% !important;
}

.tour-grid p
{
	padding-left: 7px !important;
}
.example-box
{
	display: block !important;
}

/*.header-logo-bg
{
	background: transparent !important;
	padding:0 !important;
	box-shadow: none !important;
}*/
.transparent-btn-bg {
    background: transparent !important;
    border: 0;
    margin-left: -20px !important;
    position: absolute !important;
    top: 10px !important;
}
.tour-grid
{
	display: flex;
	/* flex-direction: column !important; */
}

.mat-expansion-panel:not([class*=mat-elevation-z])
{
	box-shadow: none !important;
}
.mat-chip-list-wrapper
{
	flex-direction: column !important;
	}
	.mobile-margin, .big-ass-button
{
	margin-top: 0 !important;
}
.header-logo-bg
{
	margin-left:1rem;
}
.inputLine
{
	width: 100% !important;
}



}



@media screen and (min-width: 768px) and (max-width: 991px)
{
/*.bg-img
{
width: 220px !important;
}*/
/*.bg-img
{
width: 100% !important;
}*/
/*.formMargin
{
	flex-direction: row !important;
}*/
.child-div
{
	font-size: 1.5rem !important;
}

/*.centertoleft {
	position: relative;
	animation: none !important;
}


.centertoright {
	position: relative;
	animation: none !important;
}
*/
/*.content-container
{
	height: 100vh;
}*/
}


.imagePlacing
{
	font-family: var(--lp-font-family);
}

.mat-form-field
{
	width: 100%;
}

.mat-form-field-flex
{
	width: 98% !important;
	padding-left: 2%;
}

/*ADA Styles*/

.adafix
{
	display: none;
}

.star
{
 filter: contrast(1);
}

.tour-grid
{
	display: flex;
	justify-content: space-between;
	align-items: center;
	/*flex-grow: 1;*/
	
	width: 100%;
}

.tour-grid p
{
	margin-bottom: 0 !important;
}

.mat-list-base
{
	background: transparent;	
}

.filter
{
	border-bottom: 1px solid #ddd !important;
	margin-bottom: 0 !important;
}

.tour-grid h4
{
margin-bottom: 0;
width:170px;
}

.column1
{
	width:350px;	
	/* display: flex; */
}

.column2
{
	width:216px;	
}

.column3
{
	width:70px;	
}

.mat-input-element[type="date"]
{
	/*font-size: 30px;*/
}

.mat-input-element[type="date"]::after
{
	white-space: normal !important;
}

.icon-color
{
	color:#0C4572;
	font-size: 24px; font-weight: bold;
}

.filter-wrapper
{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.tour-user
{
	border-radius: 50%; margin-right: 10px;
}

.tour-list-container
{
	width: 92%;
    margin: 0 auto;
    padding: 0;
    position: relative;
}

.mat-form-field-wrapper
{
	/*width: 180px;*/
	/*width: 180px;*/
}

.filter-wrapper
{
	width: 180px !important;
}

.mat-form-field-infix
{
	width: 180px !important;
}

.searchbar-button-wrapper
{
	position: relative;
}



/*.mat-drawer-container
{
	min-height: 100vh !important;
}
*/

.border-1
{
	border-left:2px solid #000;
	padding-left: 1rem;
}

a
{
	text-decoration: none;
}

a:hover
{
	color: #0c4674 !important;
}

.btnColor:hover
{
	background: #0c538d !important;
}

.newMember:hover
{
color: #000 !important;
}

.signup:hover
{
	color: #000 !important;
	border-color: #000 !important;
}

.big-ass-button:hover
{
	background: #0c538d !important;
	border-color: #0c538d !important;
}

.cancel-button:hover
{
	color: #000 !important;
	border-color: #000 !important;
}

/*.child-div img:hover
{
	transform: translateX(100%);
}
*/


/* Push */
@-webkit-keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.hvr-push {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}
.hvr-push:hover, .hvr-push:focus, .hvr-push:active {
  -webkit-animation-name: hvr-push;
  animation-name: hvr-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}



.login-title
{
	font-size: 1rem;
	font-weight: normal;
	color: #214773;
	font-family: var(--lp-font-family);
	padding-right: 10px;
	padding-left: 10px;
	margin-right: 20px;
	cursor: pointer;
}

/*.cancel-button .login-title
{
	color: #0c538d;
}*/

.cancel-button .login-title:hover
{
	color: #fff;
}

.cancel-button:hover
{
	color: #fff;
}

.mat-icon-button
{
	background: transparent !important;
}


.mat-icon
{
	filter: contrast(1);
}

.transparent-bg
{
	background:transparent !important;
}

/*.mat-typography p
{
	line-height: 1.3;
}*/

.error
{
	color:red;
	font-size: .9rem;
}

.agent-details div:first-child
{
	width: 100px;
/*    display: flex;*/
    align-self: flex-start;
}

.mat-chip-list-wrapper
{
/*    position: relative;
    display: block !important;*/
    flex-wrap: nowrap !important;
    /*flex-direction: column !important;*/
    margin:7px !important;
   }

.create-form-address
{
	width: 100% !important;
}

.mat-standard-chip
{
	font-family: var(--lp-font-family);

}

@media screen and (min-width: 626px) and (max-width: 767px)
{
/*.empty-space {
    background: #fff;
    height: 39px;
    margin-top: -34px;
}*/
.empty-space
{
	background: none !important;
	margin-top: -49px !important
}
}

@media screen and (min-width: 480px) and (max-width: 767px)
{
.livpadLogo
{
	width: 111px !important;
}
.triangle-bg
{
	width: 554px !important;
}

.example-box
{
	display: block !important;
}
}

@media screen and (min-width: 480px) and (max-width: 625px)
{
.empty-space
{
	margin-top: -49px !important
}
}

/*Title alignment fix for large heading */
@media screen and (min-width: 320px) and (max-width: 1170px)
{
/*.title
{
	display: block !important;
	}*/
/*	.small-buttons
	{
		position: static !important;
		margin-top: 1rem;
	}*/
	.content-container h1
	{
		margin-left: 1rem !important;
	}
	.cancel-button
	{
		background-color: #fff !important;
		color: #0C4572 !important;
	}
	.cancel-button:active, .cancel-button:focus
{
	background-color: #fff !important;
	color: #0C4572 !important;
}
}

.createTour
{
	margin-top:0 !important;
}

@media screen and (max-width: 991px)
{
/*.cdk-overlay-pane
{
	height: 385px !important;
	overflow-y: scroll;
}*/
/*.cdk-overlay-pane .btn 
{
	margin: 0.5rem 0 !important;
}*/
}

@media screen and (min-width: 320px) and (max-width: 991px)
{
.filter-btn
{
	position: absolute;
    top: -58px;
    left:200px !important;
}
}

@media screen and (min-width: 992px) 
{
.filter-btn
{
	position: absolute;
    top: -58px;
    left:250px !important;
}
}

@media screen and (min-width: 600px) and (max-width: 991px) 
{
.column-1, .column-2, .column-3
{
	width: 100% !important;
}
.tour-grid
{
	/* flex-direction: column !important;
	align-items: flex-start !important; */
}
/*.mat-expansion-panel-header {
    padding-top: 77px !important;
    padding-bottom: 77px !important;
}*/
.tour-grid p {
    padding-left: 7px !important;
}
.border-1
{
	border:0 !important;
}
.example-box{
	/* flex-direction: column !important;
	align-items: flex-start !important; */
}
}

@media screen and (min-width: 992px) and (max-width: 1280px) 
{
.column1{
        width: 200px !important;
        word-wrap: pre-wrap;
}
.column2
{
	width: 167px !important;
	white-space: pre-wrap;
}
}
.column1, .column2
{
border-right: 1px solid #ccc;
line-height: 47px;
min-height: 47px;
padding-left: 1px;
padding-right: 1px;
}


@media screen and (min-width: 320px) and (max-width: 991px)
{
.details-wrapper
{
	flex-direction: column;	
}
.details-content
{
	margin-bottom: 15px;
}
.details-content
{
	width: 100% !important;
}
.blue-title-bg
{
	line-height: 1.3;
}

.image img, video
{
	width: 100% !important;
}

}

.align-error-message
{
	display: flex;
	align-items: center;
	font-family: var(--lp-font-family);
}


.mat-expansion-panel-header
{
	height: auto !important;
}

@media screen and (min-width: 320px) and (max-width: 991px)
{
	.column1, .column2{
		/* padding:10px 0;
		border-bottom: 1px solid #eee;
		width: 100% !important;
		border-right: 0; */
	}
	/*.mat-mini-fab .mat-button-wrapper
	{
		padding:0 !important;
	}*/
	.border-1
	{
		padding-left: 10px !important;
	}
}

.mt-1
{
	margin-top: 1rem !important;
}

.mt-2
{
	margin-top: 2rem !important;
}

/*.mt-3
{
	margin-top: 3rem !important;
}*/



.mt-4
{
	margin-top: 4rem !important;
}



.mat-select-search-hidden
{
	display: none;
}

.mat-content
{
	overflow:visible !important;
}

.nvda-splitter
{
	display: flex;
	justify-content: center;
	align-items: center;
}

.headImage p
{
	display: inline;
}

.title h1 img
{
	margin-right:15px;
}


.flex-wrapper
{
	display: flex;
	justify-content: space-between;
	align-items: center;
	/*width: 30%;*/
}

.flex-wrapper div:last-child
{
	text-align: left;
    width: 261px;
    border-left: 1px solid #000;
    padding-left: 30px;
}


/*.mat-form-field-flex .mat-input-element
{
	border-bottom: 1px solid #000 !important;
}
*/
.tour-list-container .mat-form-field-flex .mat-input-element
{
	border-bottom: 0 !important;
}

.mat-sort-header-arrow
{
	color: #9d9d9d !important;
}


/*.slide .carousel-indicators button
{
	width: 15px !important;
	height: 15px !important;
	border-radius: 50%;
}
*/
.carousel-item {
   transition: opacity .1s ease-in-out;
    transform: translate3d(0, 0, 0);
}


@media screen and (min-width: 768px) and (max-width: 991px)
{
.slide .carousel-indicators button {
    width: 9px !important;
    height: 9px !important;
    border-radius: 50%;
}
}

@media screen and (min-width: 992px)
{
.slide .carousel-indicators button {
    width: 15px !important;
    height: 15px !important;
    border-radius: 50%;
}

}

.carousel-control-next-icon, .carousel-control-prev-icon
{
	width: 3rem !important;
	height: 3rem !important;
	background-color: rgb(0,0,0,0.5);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
 /* background-image: none;*/
  line-height: 43px;
}
/*
.carousel-control-next-icon:after
{
  content: '\003E';
  font-size: 55px;
  color: #fff;
}

.carousel-control-prev-icon:after {
  content: '\003C';
  font-size: 55px;
  color: #fff;
}*/

.mat-dialog-actions
{
    flex-wrap: unset !important;
}

.mat-raised-button
{
	min-width: auto !important;
	padding: 0 10px !important;
}

 .mat-sort-header-arrow {
  opacity: 1 !important;
  transform: translateY(0px) !important;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl
{
	display: flex;
	align-items: center;
}

/* @media screen and (max-width: 991px)
{
#map
{
	height: 40vh !important;
 }
} */

@media screen and (min-width: 600px) and (max-width: 865px)
{
.small-buttons
{
	top:141px;
}
}

@media screen and (min-width: 866px) and (max-width: 1400px)
{
.small-buttons
{
	top:110px;
}
}

@media screen and (min-width: 768px)
{
.flex-wrapper
{
	display: flex;
	justify-content:left;
	align-items: center;
	/*width: 30%;*/
}
}

.tour-grid .tour-panel-center-align .mat-typography p
{
	line-height: 0 !important;
}

input[type="date"]{
	font-size: 20px;
	font-family: sans-serif;
	text-align: center;
	padding: 10px;
}

.agent-options p
{
	font-family: var(--lp-font-family);
}



/*Bootstrap Plugin for date & time*/

.input-group-addon {
  cursor: pointer;
}

.input-group.date {
  text-transform: uppercase;
}

.form-control {
  border: 1px solid rgba(126, 126, 126, 1) !important;
  box-shadow: none;
}
.form-control:hover, .form-control:focus, .form-control:active {
  box-shadow: none;
}
.form-control:focus {
  border: 1px solid #34495e;
}

/*body {
  background: #e0e0e0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 21px;
  padding: 15px 0;
}*/

/*h1 {
  color: #333;
  font-family: var(--lp-font-family);
  font-size: 28px;
  line-height: 42px;
  margin: 0 0 15px;
  text-align: center;
}*/

/*.content {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075), 0 2px 4px rgba(0, 0, 0, 0.0375);
  padding: 30px 30px 20px;
}
*/
.bootstrap-datetimepicker-widget.dropdown-menu {
  border: 1px solid #34495e;
  border-radius: 0;
  box-shadow: none;
  margin: 10px 0 0 0;
  padding: 0;
  min-width: 300px;
  max-width: 100%;
  width: auto;
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before, .bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  display: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td,
.bootstrap-datetimepicker-widget.dropdown-menu table th {
  border-radius: 0;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td.old, .bootstrap-datetimepicker-widget.dropdown-menu table td.new {
  color: #bbb;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td.today:before {
  border-bottom-color: #0095ff;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td.active,
.bootstrap-datetimepicker-widget.dropdown-menu table td.active:hover,
.bootstrap-datetimepicker-widget.dropdown-menu table td span.active {
  background-color: #0095ff;
  text-shadow: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td.active.today:before,
.bootstrap-datetimepicker-widget.dropdown-menu table td.active:hover.today:before,
.bootstrap-datetimepicker-widget.dropdown-menu table td span.active.today:before {
  border-bottom-color: #fff;
}
.bootstrap-datetimepicker-widget.dropdown-menu table th {
  height: 40px;
  padding: 0;
  width: 40px;
}
.bootstrap-datetimepicker-widget.dropdown-menu table th.picker-switch {
  width: auto;
}
.bootstrap-datetimepicker-widget.dropdown-menu table tr:first-of-type th {
  border-bottom: 1px solid #34495e;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td.day {
  height: 32px;
  line-height: 32px;
  padding: 0;
  width: auto;
}
.bootstrap-datetimepicker-widget.dropdown-menu table td span {
  border-radius: 0;
  height: 77px;
  line-height: 77px;
  margin: 0;
  width: 25%;
}
.bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months tbody tr td,
.bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years tbody tr td,
.bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades tbody tr td {
  padding: 0;
}
.bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades tbody tr td {
  height: 27px;
  line-height: 27px;
}
.bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades tbody tr td span {
  display: block;
  float: left;
  width: 50%;
  height: 46px;
  line-height: 46px !important;
  padding: 0;
}
.bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades tbody tr td span:not(.decade) {
  display: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu .timepicker-picker table td {
  padding: 0;
  width: 30%;
  height: 20px;
  line-height: 20px;
}
.bootstrap-datetimepicker-widget.dropdown-menu .timepicker-picker table td:nth-child(2) {
  width: 10%;
}
.bootstrap-datetimepicker-widget.dropdown-menu .timepicker-picker table td a,
.bootstrap-datetimepicker-widget.dropdown-menu .timepicker-picker table td span,
.bootstrap-datetimepicker-widget.dropdown-menu .timepicker-picker table td button {
  border: none;
  border-radius: 0;
  height: 56px;
  line-height: 56px;
  padding: 0;
  width: 100%;
}
.bootstrap-datetimepicker-widget.dropdown-menu .timepicker-picker table td span {
  color: #333;
  margin-top: -1px;
}
.bootstrap-datetimepicker-widget.dropdown-menu .timepicker-picker table td button {
  background-color: #fff;
  color: #333;
  font-weight: bold;
  font-size: 1.2em;
}
.bootstrap-datetimepicker-widget.dropdown-menu .timepicker-picker table td button:hover {
  background-color: #eee;
}

.bootstrap-datetimepicker-widget.dropdown-menu .picker-switch table td {
  border-top: 1px solid #34495e;
}
.bootstrap-datetimepicker-widget.dropdown-menu .picker-switch table td a, .bootstrap-datetimepicker-widget.dropdown-menu .picker-switch table td span {
  display: block;
  height: 40px;
  line-height: 40px;
  padding: 0;
  width: 100%;
}

.todayText:before {
  content: "Today's Date";
}

.app-create-user-dialog .mat-dialog-container {
    padding: 0 !important;
	background: #eee;
/*    width: 900px;*/
}

.app-create-user-dialog
{
    overflow: auto;
}

.mat-content
{
	align-items: center;
	display: flex;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl
{
	flex-wrap: wrap;
}

.mapboxgl-ctrl-geocoder--icon .mapboxgl-ctrl-geocoder--icon-search
{
	order:1;
}

.mapboxgl-ctrl-geocoder--input
{
	order:2;
}

.mapboxgl-ctrl-geocoder--pin-right
{
	order:3;
}



.suggestions-wrapper
{
	background: #fff;
	order:4;
	flex-basis: 100%;
}
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

.btn-primary
{
	background: rgba(33, 71, 115, 1);
	color:#fff !important;
	padding: 7px 4rem;
	border:0;
	font-size:16px;	    
    margin-right: 20px;
    border-radius: 10px;
    border: 1px solid rgba(33, 71, 115, 1) !important;
}

.btn-secondary
{
	border: 1px solid rgba(126, 126, 126, 1) !important;
	background: #fff !important;
	color: #0C4675 !important;
	padding: 7px 4rem;
	border:0;
	font-size:16px;
	border-radius: 10px;
}

.btn-primary:hover, .btn-primary:active
{
	background: rgba(33, 71, 115, 1) !important;
}
.browse-btn:focus,
.signup:focus{
	box-shadow: 0 0 0 .25rem rgb(33 71 115 / 28%);
}
.btnColor:focus,
.btn-primary:focus
{
	background: rgba(33, 71, 115, 1) !important;
  /* box-shadow: 0 0 0 .25rem rgb(33 71 115 / 28%); */
  box-shadow: none !important;
}
.btn-secondary:hover, .btn-secondary:active
{
	background: #fff !important;
	color: #000 !important;
	border: 1px solid #000000 !important;
}
.btn-secondary:focus {
	box-shadow: none !important;
}

.edit-link {
    color: rgba(21, 96, 147, 1);
    text-decoration: underline;
    cursor: pointer;
}

.edit-link:hover {
    color: rgba(21, 96, 147, 1);
    text-decoration: none;
    cursor: pointer;
}

.delete-link {
    color: rgba(237, 64, 54, 1);
    text-decoration: underline;
    cursor: pointer;
    filter: contrast(1);
}

.delete-link:hover {
    color: rgba(237, 64, 54, 1) !important;
    text-decoration: none;
    cursor: pointer;
}

.cards-section-admin h2
{
	border-bottom: 0;
}

.app-create-user-dialog .mat-dialog-container
{
	background: #f8f8f8 !important;
}

.input-group
{
	border:1px solid #7e7e7e !important;
}

.btn-primary.disabled, .btn-primary:disabled
{
	background: #214773;
}

.btn-secondary:active
{
	color: #000 !important;
}

.bi-funnel-fill
{
	color:#214773 !important;
}

.btn-primary:hover
{
	background: #214773 !important;
    border-color: #214773 !important;
}

.bi-funnel-fill
{
	float:left;
	margin-top:-3px;
}

.bi-search
{
	float:right;
	margin-top:-3px;
}

@media screen and (max-width: 767px) 
{
.modal-content, .model-content1, .modal-content2, .modal-content3
{
	max-width:90% !important;
}
.close-icon
{
	top: 1rem !important;
    right: 1rem !important;
}
}

button.btn-primary:disabled{
	background: #214773AA !important;
    color: #fff !important;
    border:0 !important;
}

.cursor,
.delete-btn,
.delete-link,
.edit-link,
.requiredField,
.ng-placeholder,
.required-text,
.required,
.form-group span,
.text-success,
.wave5text,
.onChecked, 
.onUnchecked, 
.editprofile,
.section-header span,
/* span, */
.error-message,
.text-danger {
  filter: contrast(1);
}
span.mat-content {
	filter: none !important;
}

.mat-h2, .mat-title, .mat-typography .mat-h2, .mat-typography .mat-title, .mat-typography h2,
.mat-h5, .mat-typography .mat-h5, .mat-typography h5{
	font-family: var(--lp-font-family) !important;
}


